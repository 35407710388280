import './ListItem.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useCallback, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import DatePicker from 'react-date-picker';
import IconButton from '@mui/material/IconButton';
import ListItemActionsButton from './ListItemActionsButton';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';

const higherElevation = 8;

const priorities = {
  1: { color: 'red', fontWeight: 'bold' },
  2: { color: 'hotpink', fontWeight: '500' },
  3: { color: 'grey' },
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default function ListItem({
  backgroundColor,
  defaultElevation,
  deleteTask,
  handleOnClick,
  isMobile,
  moveTaskLeft,
  moveTaskRight,
  moveTaskToNextPanel,
  moveTaskToPreviousPanel,
  removeTaskFromPanel,
  task,
  updateTask,
}) {
  const [elevation, setElevation] = useState(defaultElevation);
  const [showIcons, setShowIcons] = useState(false);

  const [priority, setPriority] = useState(
    typeof task?.priority === 'number' ? task.priority : ''
  );
  useEffect(() => {
    setPriority(task?.priority);
  }, [task?.priority]);

  const [date, setDate] = useState();
  useEffect(() => {
    const taskDate = task?.date;
    if (taskDate) {
      const formattedDate = new Date(taskDate);
      setDate(formattedDate);
    }
  }, [task?.date]);

  // let clickStartMs;
  return (
    <Paper
      // variant={'outlined'}
      sx={{
        backgroundColor,
        color: 'text.secondary',
        cursor: handleOnClick ? 'pointer' : '',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 90,
        padding: '16px 8px 16px 8px',
        textAlign: 'center',
        borderRadius: 2,
        position: 'relative',
      }}
      elevation={elevation}
      key={task.id}
      // onMouseDown={(event) => {
      //   clickStartMs = Date.now();
      // }}
      onMouseUp={(event) => {
        // const clickTimeMs = Date.now() - clickStartMs;
        // if (clickTimeMs > 600) {
        //   deleteTask(task);
        // } else {
        if (
          !isMobile &&
          deleteTask &&
          (event.button === 1 || (event.button === 0 && event.shiftKey))
        ) {
          deleteTask(task);
        } else if (handleOnClick && event.button === 0) {
          handleOnClick(task);
        }
      }}
      // onClick={() => handleOnClick(task)}
      onMouseOver={() => {
        setElevation(higherElevation);
        setShowIcons(true);
      }}
      onMouseLeave={() => {
        setElevation(defaultElevation);
        setShowIcons(false);
      }}
    >
      <select
        name="priority"
        onChange={(e) => {
          const newPriority = parseInt(e.target.value);
          if (newPriority) {
            const updates = { priority: newPriority };
            updateTask({ taskId: task?.id, updates });
          }
        }}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          color: priorities[priority]?.color || '',
          cursor: 'pointer',
          fontSize: '14px',
          fontWeight: priorities[priority]?.fontWeight || '',
          left: '4px',
          padding: '2px',
          position: 'absolute',
          outline: 'none',
          top: '4px',
          width: 'fit-content',
        }}
        value={priority}
      >
        <option value={''} selected disabled hidden>
          -
        </option>
        {Object.keys(priorities).map((priorityValue) => (
          <option value={priorityValue}>{`P${priorityValue}`}</option>
        ))}
      </select>
      <div
        className="date-picker-wrapper"
        style={{ right: date ? '4px' : '-20px' }}
      >
        <DatePicker
          calendarIcon={false}
          clearIcon={false}
          className={'custom-date-picker'}
          format="dd"
          maxDetail={'month'}
          onChange={(newDate) => {
            if (newDate) {
              const updates = { date: newDate.getTime() };
              updateTask({ taskId: task?.id, updates });
            }
          }}
          value={date}
        />

        <div className="date-picker-wrapper-month">
          {date ? months[date.getMonth()].substring(0, 3) : ''}
        </div>
      </div>

      {deleteTask || moveTaskToNextPanel || moveTaskToPreviousPanel ? (
        <Box position="absolute" top="8%" right="5%">
          <ListItemActionsButton
            color={showIcons ? '' : 'transparent'}
            deleteTask={deleteTask ? () => deleteTask(task) : null}
            moveTaskToNextPanel={
              moveTaskToNextPanel ? () => moveTaskToNextPanel(task) : null
            }
            moveTaskToPreviousPanel={
              moveTaskToPreviousPanel
                ? () => moveTaskToPreviousPanel(task)
                : null
            }
            removeTaskFromPanel={
              removeTaskFromPanel ? () => removeTaskFromPanel(task) : null
            }
          />
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          // alightItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <IconButton
            disableRipple
            size="small"
            aria-label="move left"
            disabled={!moveTaskLeft}
            onClick={() => (moveTaskLeft ? moveTaskLeft(task) : null)}
          >
            <ArrowBackIosNewIcon
              sx={{
                color: showIcons && moveTaskLeft ? '' : 'transparent',
              }}
              fontSize="10"
            />
          </IconButton>
        </Box>

        <Box
          sx={{
            paddingBottom: '6px',
            paddingTop: '6px',
            overflow: 'hidden',
          }}
        >
          <Box>
            <EditableTitle task={task} updateTask={updateTask} />
          </Box>
          {task?.topic?.name ? (
            <Box
              sx={{
                paddingTop: '7px',
              }}
            >
              <b>{`[${task.topic.name}] `}</b>
            </Box>
          ) : null}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <IconButton
            disableRipple
            size="small"
            aria-label="move right"
            disabled={!moveTaskRight}
            onClick={() => (moveTaskRight ? moveTaskRight(task) : null)}
          >
            <ArrowForwardIosIcon
              sx={{ color: showIcons && moveTaskRight ? '' : 'transparent' }}
              fontSize="10"
            />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
}

function EditableTitle({ task, updateTask }) {
  const divRef = useRef();

  const taskTitle = task?.title || '-';
  const handleSubmit = useCallback(
    (e) => {
      const newText = e.target.textContent;
      if (newText !== taskTitle) {
        const updates = {
          title: newText,
        };
        updateTask({ taskId: task?.id, updates });
      }
    },
    [taskTitle, task?.id, updateTask]
  );

  const handleKeyDown = useCallback((e) => {
    if ((e.key === 'Enter' && !e.shiftKey) || e.key === 'Escape') {
      divRef.current.blur();
    }
  }, []);

  return (
    <div
      className="task-editable-title"
      ref={divRef}
      contenteditable="true"
      onBlur={handleSubmit}
      onKeyDown={handleKeyDown}
    >
      {taskTitle}
    </div>
  );
}
